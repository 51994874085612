import React from "react";
import { Link, graphql } from "gatsby";

export const fragment = graphql`
    fragment Downloadable on SanityDownloadable {
        _key
        _type
        downloadname
        downloadfile {
            asset {
                url
            }
        }
    }
`;

const DownloadFile = (props) => {
    return (
        <>
            {props.downloadfile ? (
                <a className="button" href={props.downloadfile.asset.url}>
                    {props.downloadname}
                </a>
            ) : null}
        </>
    );
};

export default DownloadFile;
