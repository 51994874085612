import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../layout/layout";
import StyledLink from "../components/styledlink";
import FormattedText from "../components/portableText";
import Header from "../components/header";
import TextBlock from "../components/textblock";
import Image from "../components/image";
import Carousel from "../components/carousel";
import UnorderedList from "../components/unorderedlist";
import Video from "../components/video";
import DownloadFile from "../components/download";

const LearningResourceTemplate = ({ data }) => {
    const resource = data.sanityLearningResource;
    const sections = data.sanityLearningResource.sections;

    useEffect(() => {
        if (data.sanityLearningResource) {
            document.body.classList.add("learning-resource");
        }
    });
    var spanClass = {
        textTransform: "uppercase",
        fontWeight: "400",
        fontSize: "0.8em",
        letterSpacing: "1.2px",
        display: "inline-block",
        lineHeight: "1em",
        marginRight: "0",
        paddingTop: "3px",
    };
    var spanType = {
        paddingRight: "10px",
        borderRight: "solid 1px #282828",
    };
    var spanTags = {
        paddingLeft: "10px",
    };

    return (
        <>
            <Layout>
                <div className="px-6 mb-24 lr-banner theme-bg">
                    <div className="container flex flex-wrap w-full pt-24 mx-auto sm:w-5/6 md:w-2/3 pb-36">
                        <div className="w-full">
                            <span style={{ ...spanType, ...spanClass }}>
                                {resource.type.map((resourceType) => resourceType)}
                            </span>

                            {resource.tags.map((tag) => (
                                <span style={{ ...spanClass, ...spanTags }}>{tag.project_tag}</span>
                            ))}

                            <h1 className="heading">{data.sanityLearningResource.resource_name}</h1>
                        </div>
                        <div className="w-full sm:w-2/3">
                            <p>{resource.short_desc}</p>
                        </div>
                    </div>
                </div>
                <div className="container flex flex-wrap w-full mx-auto sm:w-5/6 md:w-2/3">
                    <main className="flex flex-wrap w-full p-6 sm:w-5/6 md:w-2/3">
                        <FormattedText blocks={resource._rawResourceDescription} />
                        <div className="container mx-auto">
                            {sections.map((section) => {
                                const typeName = section.__typename;
                                console.log(section);
                                switch (typeName) {
                                    case "SanityHeader":
                                        return <Header key={section._key} {...section} />;
                                    case "SanityTextBlock":
                                        return <TextBlock key={section._key} {...section} />;
                                    case "SanityImageBlock":
                                        return <Image key={section._key} {...section} />;
                                    case "SanityImgSlider":
                                        return <Carousel key={section._key} {...section} />;
                                    case "SanityUnorderedList":
                                        return <UnorderedList key={section._key} {...section} />;
                                    case "SanityVideo":
                                        return <Video key={section._key} {...section} />;
                                    case "SanityButton":
                                        return (
                                            <StyledLink
                                                key={section._key}
                                                link={section.buttonLink}
                                                linkText={section.buttonTitle}
                                                {...section}
                                            />
                                        );
                                    case "SanityDownloadable":
                                        return <DownloadFile key={section._key} {...section} />;
                                    default:
                                        return <p>Nothing to see here</p>;
                                }
                            })}
                        </div>
                        {resource.download ? (
                            <StyledLink link={resource.download.asset.url} linkText="Download" />
                        ) : null}
                    </main>
                    <aside className="flex-wrap hidden w-full sm:w-1/6 md:w-1/3 sm:flex">
                        {resource.image ? (
                            <GatsbyImage
                                image={resource.image.asset.gatsbyImageData}
                                alt={data.sanityLearningResource.resource_name}
                                style={{ height: "auto" }}
                            />
                        ) : null}
                    </aside>
                </div>
            </Layout>
        </>
    );
};

export const query = graphql`
    query ($slug: String) {
        sanityLearningResource(resource_url: { current: { eq: $slug } }) {
            id
            _rawResourceDescription
            resource_name
            resource_url {
                current
            }
            short_desc
            type
            tags {
                project_tag
            }
            download {
                asset {
                    url
                    title
                }
            }
            image {
                asset {
                    gatsbyImageData
                }
            }
            sections {
                __typename
                ...TextBlockSection
                ...ImageBlock
                ...Slider
                ...UnorderedListSection
                ...Header
                ...VideoSection
                ...Button
                ...Downloadable
            }
        }
    }
`;

export default LearningResourceTemplate;
